<script>
import Swal from 'sweetalert2';

export default {
  props: {
    isEditar: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      waiting: false,
    }
  },
  computed: {
    empresaId() {
      return this.$route.params.id;
    }
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({
      path: `Empresa/GetEmpresaById/${this.empresaId}`
    });
    console.log('response contacto', response)
    const data = await response.json();

    console.log('data contacto', data)

    Object.keys(data).forEach(key => {
      const input = this.$el.querySelector(`[name="${key}"]`);
      if (input) {
        input.value = data[key];
      }
    });
  },
  methods: {
    saveData(event) {
      const fields = Object.fromEntries(new FormData(event.target).entries());
      console.log(fields);

      const data = {}
      Object.keys(fields).forEach(key => {
        if (fields[key]) {
          data[key] = fields[key];
        }
      });

      console.log(data);

      this.waiting = true;
      this.$store.getters.fetchPut({
        path: `Empresa/UpdateEmpresa2024/${this.empresaId}`,
        data
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error("No se ha guardado la información de contacto")
        })
        .then(result => {
          console.log('result', result);
          this.savedSuccess();
        })
        .catch(() => {
          this.savedError();
        })
        .finally(() => {
          this.waiting = false;
        });
    },

    savedSuccess() {
      Swal.fire({
        title: 'Guardada',
        text: 'Se ha guardado la información de contacto',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      })
    },

    savedError() {
      Swal.fire({
        title: 'Error',
        text: 'No se ha guardado la información de contacto',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    }
  }
}
</script>

<template>
  <card>
    <h4 class="px-4 py-4"><b>Información contacto</b></h4>

    <form class="form" @submit.prevent="saveData">

      <div class="wrapper-fields">

        <div class="wrapper-input" title="Nombre del contacto">
          <label for="nombreContacto">Primer nombre</label>
          <b-form-input id="nombreContacto" name="nombreContacto" required></b-form-input>
        </div>


        <div class="wrapper-input" title="Nombre del contacto">
          <label for="segundoNombreContacto">Segundo nombre</label>
          <b-form-input id="segundoNombreContacto" name="segundoNombreContacto"></b-form-input>
        </div>


        <div class="wrapper-input" title="Nombre del contacto">
          <label for="primerApellidoContacto">primer apellido</label>
          <b-form-input id="primerApellidoContacto" name="primerApellidoContacto" required></b-form-input>
        </div>


        <div class="wrapper-input" title="Nombre del contacto">
          <label for="segundoApellidoContacto">Segundo apellido</label>
          <b-form-input id="segundoApellidoContacto" name="segundoApellidoContacto"></b-form-input>
        </div>


        <div class="wrapper-input" title="Número del Contacto">
          <label for="telefonoContacto">Número del Contacto</label>
          <b-form-input id="telefonoContacto" name="telefonoContacto" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Correo electrónico del Contacto">
          <label for="correo">Correo electrónico del Contacto</label>
          <b-form-input id="correo" name="correo" required type="email"></b-form-input>
        </div>

        <div class="wrapper-input" title="Contraseña para IFrames">
          <label for="contrasenaIFrame">Contraseña para IFrames</label>
          <b-form-input id="contrasenaIFrame" name="contrasenaIFrame" required></b-form-input>
        </div>
      </div>
      <div class="d-flex pt-0 mt-0 justify-content-end     pr-0"   >
        <vs-button gradient floating :loading="waiting"  style="width: 3.3rem; height: 3.3rem;"   class="mx-1  "><i style="font-size: 2rem;"  class="fa-solid fa-floppy-disk"></i></vs-button>
      </div>

      <!-- <vs-button class="button"   :loading="waiting">Guardar</vs-button> -->
    </form>
  </card>
</template>


<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}


.form {
  padding: 0 48px 16px;
  display: flex;
  flex-direction: column;
}

.form .button {
  align-self: flex-end;
}
</style>