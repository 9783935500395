<script>
import Swal from 'sweetalert2';

export default {
  name: 'EditarEmpresaInformacionGeneral',

  async mounted() {

    const response = await this.$store.getters.fetchGet({
      path: `Empresa/GetEmpresaById/${this.empresaId}`
    });
    const data = await response.json();


    // Como tengo datos conocidos. Puedo optimizar la la velocidad de carga de la vista por los selects.
    Promise.all([
      this.$store.getters.fetchGet({ path: 'Pais/all' }),
      this.$store.getters.fetchGet({ path: `DepartamentoPais/ListByPais/${data.paisId}` }),
      this.$store.getters.fetchGet({ path: `Municipio/ListByDepartamento/${data.departamentoId}` })
    ])
      .then(([responsePaises, responseDepartamentos, responseMunicipios]) => {
        return Promise.all([
          responsePaises.json(),
          responseDepartamentos.json(),
          responseMunicipios.json()
        ]);
      })
      .then(([paises, departamentos, municipios]) => {
        this.paises = paises;
        this.departamentos = departamentos;
        this.municipios = municipios;
      })
      .finally(() => {
        Object.keys(data).forEach(key => {
          const input = this.$el.querySelector(`[name="${key}"]`);
          if (input) {
            input.value = data[key];
          }
        });
      });
  },
  data() {
    return {
      waiting: false,
      paises: [],
      departamentos: [],
      municipios: []
    }
  },
  computed: {
    empresaId() {
      return this.$route.params.id;
    }
  },
  methods: {
    saveData(event) {
      if (this.waiting) return;

      const fields = Object.fromEntries(new FormData(event.target));

      const data = {}
      Object.keys(fields).forEach(key => {
        if (fields[key]) {
          data[key] = fields[key];
        }
      });

      console.log(data);

      this.waiting = true;
      this.$store.getters.fetchPut({
        path: `Empresa/UpdateEmpresa2024/${this.empresaId}`,
        data
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error("No se ha guardado la empresa")
        })
        .then(result => {
          console.log('result', result);
          this.savedSuccess();
        })
        .catch(() => {
          this.savedError();
        })
        .finally(() => {
          this.waiting = false;
        });
    },

    savedSuccess() {
      Swal.fire({
        title: 'Empresa guardada',
        text: 'Se ha guardado la empresa',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      })
    },

    savedError() {
      Swal.fire({
        title: 'Error',
        text: 'No se ha guardado la empresa',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
    },

    async getDepartamentosByPaisId(paisId) {
      const responseDepartamentos = await this.$store.getters.fetchGet({
        path: `DepartamentoPais/ListByPais/${paisId}`
      });
      this.departamentos = await responseDepartamentos.json();
    },

    async getMunicipiosByDepartamentoId(departamentoId) {
      const responseMunicipios = await this.$store.getters.fetchGet({
        path: `Municipio/ListByDepartamento/${departamentoId}`
      });
      this.municipios = await responseMunicipios.json();
    },

    onInputPais(paisId) {
      this.municipios = [];
      this.departamentos = [];

      const inputDepartamento = document.querySelector('[name="departamentoId"]');
      if (inputDepartamento) {
        inputDepartamento.value = null;
      }
      const inputCiudad = document.querySelector('[name="ciudadId"]');
      if (inputCiudad) {
        inputCiudad.value = null;
      }

      this.getDepartamentosByPaisId(paisId);
    },

    onInputDepartamento(departamentoId) {
      this.municipios = [];
      const inputCiudad = document.querySelector('[name="ciudadId"]');
      if (inputCiudad) {
        inputCiudad.value = null;
      }

      this.getMunicipiosByDepartamentoId(departamentoId);
    }
  },
}
</script>

<template>
  <card>
    <template v-slot:headerTitle>
      <h4 class="card-title"><b>MODIFICAR EMPRESA</b></h4>
    </template>
    <h4 class="px-4 py-4"><b>Información General</b></h4>

    <form class="form" @submit.prevent="saveData">

      <div class="wrapper-fields mb-0 pb-0">

        <div class="wrapper-input" title="Nombre de la empresa">
          <label for="nombre">Nombre</label>
          <b-form-input id="nombre" name="nombre" required></b-form-input>
        </div>

        <!-- <div class="wrapper-input" title="Sector">
          <label for="sectorId">Sector</label>
          <HelexSelect id="sectorId" name="sectorId" :options="[{name: 'x', id: 0}]" optionLabel="name" optionValue="id"></HelexSelect>
        </div> -->

        <div class="wrapper-input" title="Número de Identificación Tributaria">
          <label for="nit">NIT</label>
          <b-form-input id="nit" name="nit" required></b-form-input>
        </div>

        <div class="wrapper-input" title="Dígito de verificación">
          <label for="dv">DV</label>
          <b-form-input id="dv" name="dv" required></b-form-input>
        </div>

        <div class="wrapper-input" title="País">
          <label for="paisId">País</label>
          <HelexSelect @input="onInputPais" id="paisId" name="paisId" :options="paises" optionLabel="descripcion"
            optionValue="id" required>
          </HelexSelect>
        </div>

        <div class="wrapper-input" title="Departamento">
          <label for="departamentoId">Departamento</label>
          <HelexSelect @input="onInputDepartamento" id="departamentoId" name="departamentoId" :options="departamentos"
            optionLabel="descripcion" optionValue="id" required></HelexSelect>
        </div>

        <div class="wrapper-input" title="Municipio / ciudad">
          <label for="ciudadId">Municipio</label>
          <HelexSelect id="ciudadId" name="ciudadId" :options="municipios" optionLabel="descripcion" optionValue="id" required>
          </HelexSelect>
        </div>

        <div class="wrapper-input" title="Dirección principal">
          <label for="direccionDelDomicilioPrincipal">Dirección principal</label>
          <b-form-input id="direccionDelDomicilioPrincipal" name="direccionDelDomicilioPrincipal"
            required></b-form-input>
        </div>

      </div>
      <div class="d-flex pt-0 mt-0 justify-content-end     pr-0"   >
        <vs-button gradient floating :loading="waiting"  style="width: 3.3rem; height: 3.3rem;"   class="mx-1  "><i style="font-size: 2rem;"  class="fa-solid fa-floppy-disk"></i></vs-button>
      </div>
      <!-- <vs-button class="button"   :loading="waiting">Guardar</vs-button> -->
    </form>

  </card>
</template>

<style scoped>
.wrapper-input {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.wrapper-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}


.form {
  padding: 0 48px 16px;
  display: flex;
  flex-direction: column;
}

.form .button {
  align-self: flex-end;
}
</style>